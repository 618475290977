// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  padding: 50px;
  background-color: #f8f8f8;
  text-align: center;
}

.home-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.home-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
  color: #555;
}

.home-content ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
  display: inline-block;
}

.home-content ul li {
  font-size: 1rem;
  margin: 10px 0;
  color: #444;
}

.cta-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.cta-button a {
  text-decoration: none;
  color: white;
}

.cta-button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".home {\n  padding: 50px;\n  background-color: #f8f8f8;\n  text-align: center;\n}\n\n.home-content h1 {\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n  color: #333;\n}\n\n.home-content p {\n  font-size: 1.2rem;\n  line-height: 1.8;\n  margin-bottom: 30px;\n  color: #555;\n}\n\n.home-content ul {\n  list-style: none;\n  padding: 0;\n  margin: 20px 0;\n  text-align: left;\n  display: inline-block;\n}\n\n.home-content ul li {\n  font-size: 1rem;\n  margin: 10px 0;\n  color: #444;\n}\n\n.cta-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  font-size: 1rem;\n  border-radius: 5px;\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.cta-button a {\n  text-decoration: none;\n  color: white;\n}\n\n.cta-button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
