import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <section className="home">
      <div className="home-content">
        <h1>Transform Your Space with Vastu Expertise</h1>
        <p>
          At <strong>Starweeve Resonance</strong>, we specialize in crafting
          harmonious living and working environments through the ancient science
          of <strong>Vastu Shastra</strong>. Our consultancy services are
          designed to align your spaces with the natural energies of the
          universe, fostering peace, prosperity, and well-being.
        </p>
        <ul>
          <li>
            <strong>Personalized Vastu Analysis:</strong> Tailored
            recommendations to optimize energy flow and remove blockages.
          </li>
          <li>
            <strong>Remedial Solutions:</strong> Practical remedies to
            neutralize negative energies without structural changes.
          </li>
          <li>
            <strong>Interior Alignment:</strong> Align furniture, colors, and
            elements with Vastu principles.
          </li>
          <li>
            <strong>New Home Consultation:</strong> Guidance in designing a
            space that promotes success and happiness.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Home;
