import React from "react";
import "./Navbar.css";
import { navlinks } from "../Assets/data";

const Navbar = () => {
  return (
    <nav className="nav">
      <div className="logo">
        <img src="logo.jpg" />
        <h2>Starweeve Resonance</h2>
      </div>
    </nav>
  );
};

export default Navbar;
