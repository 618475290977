// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  display: flex;
  padding-inline: 100px;
  justify-content: space-between;
  background: black;
  color: white;
  height: 12vh;
}

.nav .logo,
.nav .nav_links {
  margin-top: 20px;
}

.nav .logo img {
  width: 50px;
}

.nav .logo img,
.nav .logo h2 {
  display: inline-block; /* Makes elements sit side-by-side */
  vertical-align: middle; /* Aligns them vertically */
}

.nav .logo img {
  width: 50px; /* Adjust image size */
  height: auto;
  margin-right: 10px; /* Space between image and text */
}

.nav .btn {
  margin-top: 20px;
}
git status .nav .logo {
  font-size: 20px;
  margin-top: 0px;
}

.nav .nav_links ul {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  list-style-type: none;
}

.nav .nav_links a {
  font-size: 18px;
  text-decoration: none;
  list-style: none;
  color: white;
}

.nav .nav_links a:hover {
  color: gold;
}

.nav .btn button {
  background-color: gold;
  border: none;
  width: 130px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
}

.nav .btn button:hover {
  background: goldenrod;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,iBAAiB;EACjB,YAAY;EACZ,YAAY;AACd;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,qBAAqB,EAAE,oCAAoC;EAC3D,sBAAsB,EAAE,2BAA2B;AACrD;;AAEA;EACE,WAAW,EAAE,sBAAsB;EACnC,YAAY;EACZ,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".nav {\n  display: flex;\n  padding-inline: 100px;\n  justify-content: space-between;\n  background: black;\n  color: white;\n  height: 12vh;\n}\n\n.nav .logo,\n.nav .nav_links {\n  margin-top: 20px;\n}\n\n.nav .logo img {\n  width: 50px;\n}\n\n.nav .logo img,\n.nav .logo h2 {\n  display: inline-block; /* Makes elements sit side-by-side */\n  vertical-align: middle; /* Aligns them vertically */\n}\n\n.nav .logo img {\n  width: 50px; /* Adjust image size */\n  height: auto;\n  margin-right: 10px; /* Space between image and text */\n}\n\n.nav .btn {\n  margin-top: 20px;\n}\ngit status .nav .logo {\n  font-size: 20px;\n  margin-top: 0px;\n}\n\n.nav .nav_links ul {\n  display: flex;\n  justify-content: space-around;\n  gap: 20px;\n  list-style-type: none;\n}\n\n.nav .nav_links a {\n  font-size: 18px;\n  text-decoration: none;\n  list-style: none;\n  color: white;\n}\n\n.nav .nav_links a:hover {\n  color: gold;\n}\n\n.nav .btn button {\n  background-color: gold;\n  border: none;\n  width: 130px;\n  height: 50px;\n  font-size: 14px;\n  font-weight: 600;\n  border-radius: 30px;\n}\n\n.nav .btn button:hover {\n  background: goldenrod;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
